import React from "react";
import { Card, Button } from "react-bootstrap";
function Monke(props) {
  const { name, info, pfp, url } = props;

  return (
    <Card bg="dark" style={{ width: "10rem" }} className="mx-2">
      {/* <a href={url} target="_blank"> */}
      <Card.Img variant="top" src={pfp} />
      {/* </a> */}
      <Card.Body>
        {/* <Card.Title>{name}</Card.Title>
        <Card.Text>{info}</Card.Text> */}

        <Button size="sm" variant="dark">
          {name}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default Monke;
