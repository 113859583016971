import React from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import questionIcon from "../../icon-question.png";
import answerIcon from "../../icon-answer.png";

function Question(props) {
  const { question, answer, longAnswer, url, target, urlText } = props;
  return (
    <p align="left" className="m-4 pb-4">
      <h3 className="faq">
        <img src={questionIcon} alt="Q" height="40" className="mx-2" />
        {question}
      </h3>
      <img src={answerIcon} alt="A" height="33" className="mx-2" />

      <i>{answer}</i>

      {longAnswer && (
        <>
          <p className="pt-4">{longAnswer}</p>
        </>
      )}

      {url && urlText && (
        <p className="mt-2">
          <a href={url} target={target}>
            {/* <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2" /> */}
            <i className="fas fa-external-link-alt mx-2"></i>
            {urlText}
          </a>
        </p>
      )}
    </p>
  );
}

export default Question;
