import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import logo from "../../little-monke.png";
function MonkeIcon(props) {
  const { url, title } = props;
  const placement = "right";
  return (
    <>
      {title ? (
        <OverlayTrigger
          key={placement}
          placement={placement}
          overlay={
            <Tooltip id={`tooltip-${placement}`}>
              <strong>{title}</strong>
            </Tooltip>
          }
        >
          {url ? (
            <a href={url}>
              <img src={logo} alt={title} title={title} />
            </a>
          ) : (
            <img src={logo} alt={title} title={title} />
          )}
        </OverlayTrigger>
      ) : (
        <>
          {url ? (
            <a href={url}>
              <img src={logo} />
            </a>
          ) : (
            <img src={logo} />
          )}
        </>
      )}
    </>
  );
}

export default MonkeIcon;
