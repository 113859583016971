import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import title from "../../title-howitworks.png";
import generic from "../../generic.png";
import icon from "../../icon-howitworks.png";

const pageName = "How It Works";

function HowItWorks(props) {
  return (
    <>
      <a name={pageName.toLowerCase()}></a>
      <p className="App-content-alt">
        <Container>
          <Row>
            <Col>
              <img src={icon} alt={pageName} height="55" className="mr-2" />
              <img src={title} alt={pageName} height="33" className="m-2" />
              <p align="left">
                <h3>MINT:</h3> 5669 truly random MonkeShitBox NFTs at a price of
                .19 SOL per box. The contents of each MonkeShitBox will remain
                hidden until our Reveal Party, which will take place 24-48 hours
                after mint completion.{" "}
                {/* <code>
                  5&nbsp;guaranteed&nbsp;to be redeemable for a
                  Solana&nbsp;Monkey&nbsp;Business NFT!
                </code>
                &nbsp; All will have rarity and future utility. Expect some
                surprises! */}
                <h3 className="mt-4">REVEAL:</h3> Approximately 24-48 hours
                after mint completion, your box will be opened and you will be
                able to see what is inside. During this time, we will build our
                community in discord, debate who will find an SMB in their box
                and do other sorts of giveaways and collaborations.
                <h3 className="mt-4">POST REVEAL:</h3>
                <ul>
                  <li>
                    You will most likely find a shitty NFT, but it’s your shitty
                    NFT. It has special rarity and can get you into our private
                    Shit Boxers Discord channel and provides early access on
                    future drops. We will list them on marketplaces, too.
                  </li>
                  <li>
                    You might find an NFT redeemable for a Solana Monkey
                    Business NFT. There are 4 total. It will be a floor SMB NFT
                    of our choosing.
                  </li>
                  <li>
                    You might just find some other non-shitty stuff hiding in
                    the boxes. Who knows?
                  </li>
                </ul>
                <h3 className="mt-4">REDEEMING YOUR NFT:</h3> Did you get an NFT
                exchangeable for a SMB or something else? If so, we will add you
                to the #Champions Discord channel. There an admin will help you
                exchange your NFT. We will never DM you first.
                <h3 className="mt-4">THE FUTURE:</h3> The plan is to launch
                additional MonkeShitBox collections every month or so, build a
                real community, and reward those who collect our boxes so even
                if they never find an SMB they will still find real value. We
                recommend holding at least 1 from our genesis drop for future
                benefits.
              </p>
            </Col>
            {/* <Col md={5}>
              <img
                src={generic}
                alt="How it Works"
                className="App-content-image"
              />
            </Col> */}
          </Row>
        </Container>
      </p>
    </>
  );
}

export default HowItWorks;
