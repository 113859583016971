import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import title from "../../title-roadmap.png";
import icon from "../../icon-roadmap.png";
import RoadmapCard from "./RoadmapCard";

const pageName = "Roadmap";

function MSBRoadmap(props) {
  const [roadmapJson, setRoadmapJson] = useState(props.json);
  useEffect(() => {
    if (props.json !== undefined) setRoadmapJson(props.json);
  }, [props.json]);
  return (
    <>
      <a name={pageName.toLowerCase()}></a>
      <p className="App-content-alt">
        <Container>
          <Row>
            <Col>
              <img src={icon} alt={pageName} height="55" className="mr-2" />
              <img src={title} alt={pageName} height="45" className="m-2" />
            </Col>
          </Row>
          <Row>
            <Col className="roadmap">
              {roadmapJson &&
                Array.isArray(roadmapJson?.items) &&
                roadmapJson?.items?.map((item) => {
                  return (
                    <RoadmapCard
                      key={item.item}
                      title={item.title}
                      checklist={item.checklist}
                    />
                  );
                })}
            </Col>
          </Row>
        </Container>
      </p>
    </>
  );
}

export default MSBRoadmap;
