import React from "react";

import { Col, Container, Row, CardGroup } from "react-bootstrap";

import title from "../../title-team.png";
import icon from "../../icon-team.png";
import Monke from "./Monke";

import FUEGOmike from "../../monkes/FUEGOmike.png";
import Don from "../../monkes/0xDon.png";
import ba11en from "../../monkes/ba11en.png";
import deebska15 from "../../monkes/deebska15.png";
import jebivetar from "../../monkes/jebivetar.png";
import Conda from "../../monkes/Conda.png";
import Entropy from "../../monkes/Entr◎py.png";
import BeyondH from "../../monkes/BeyondH.png";
import tigurvm from "../../monkes/tigurvm.png";
import CKS from "../../monkes/CKS.png";

const pageName = "Team";

function Team(props) {
  return (
    <>
      <a name={pageName.toLowerCase()}></a>
      <p className="App-content">
        <Container>
          <Row>
            <Col>
              <img src={icon} alt={pageName} height="55" className="mr-2" />
              <img src={title} alt={pageName} height="40" className="m-2" />
              <p align="left" className="mt-3">
                This project is brought to you by{" "}
                <code>10 degen MonkeDAO members</code> who think everybody
                should have a shot at an <code>SMB</code>
                <br />
                We also believe your shot should not cost so much you regret it
                either.
              </p>
              <p align="left" className="mt-3">
                We are a <code>doxxed</code> team excited to launch this shitty
                yet fun project.
              </p>

              <Row xs={1} md={2} className="team">
                <Monke
                  role=""
                  name="@FUEGOmike"
                  url="https://twitter.com/"
                  pfp={FUEGOmike}
                />

                <Monke
                  role=""
                  name="@0xDon"
                  twitterUrl="https://twitter.com/"
                  pfp={Don}
                />

                <Monke
                  role=""
                  name="@ba11en"
                  url="https://twitter.com/"
                  pfp={ba11en}
                />

                <Monke
                  role=""
                  name="@deebska15"
                  url="https://twitter.com/"
                  pfp={deebska15}
                />

                <Monke
                  role=""
                  name="@jebivetar"
                  url="https://twitter.com/"
                  pfp={jebivetar}
                />

                <Monke
                  role=""
                  name="@Conda"
                  url="https://twitter.com/"
                  pfp={Conda}
                />

                <Monke
                  role=""
                  name="@CKS"
                  url="https://twitter.com/"
                  pfp={CKS}
                />

                <Monke
                  role=""
                  name="@Entr◎py (Investor)"
                  url="https://twitter.com/"
                  pfp={Entropy}
                />
                <Monke
                  role=""
                  name="@BeyondH (Investor)"
                  url="https://twitter.com/"
                  pfp={BeyondH}
                />

                <Monke
                  role=""
                  name="@tigurvm (Investor)"
                  url="https://twitter.com/"
                  pfp={tigurvm}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </p>
    </>
  );
}

export default Team;
