import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import logo from "../../logo.png";
import box from "../../shitbox.gif";
import magicEden from "../../magicEden.svg";
import MonkeIcon from "./MonkeIcon";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
const twitterPlacement = "left";
const discordPlacement = "right";

function Header(props) {
  return (
    <header className="App-header">
      {/* <MonkeIcon title="kek" /> */}
      <img src={logo} alt="logo" className="App-logo" />
      <br />
      <code>[Shit! it's Sold Out!]</code>
      <a
        href="https://magiceden.io/marketplace/monkeshitbox"
        target="_blank"
        class="magiceden"
      >
        We are live on
        <img src={magicEden} className="m-2" height="25" />
      </a>
      {/* <br />
      <Button
        variant="danger"
        href="https://monkeshitbox.chinesewhales.com/"
        target="_blank"
      >
        GO TO MINT SITE
      </Button> */}
      <br />
      <img src={box} alt="box" className="App-box" />
      <p>
        It's a box. You definitely want one.
        <br />
        <code>You might find a monke inside.</code>
        <br />
        More likely something shitty.
        <br />
        Probably not a rug.
        <br />
        Not endorsed by anyone sane.
      </p>
      <h1>
        <OverlayTrigger
          key={twitterPlacement}
          placement={twitterPlacement}
          overlay={
            <Tooltip id={`tooltip-${twitterPlacement}`}>
              <strong>Twitter</strong>
            </Tooltip>
          }
        >
          <a
            className="App-link m-2"
            href={process.env.REACT_APP_TWITTER_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <FontAwesomeIcon icon={faTwitter} /> */}
            <i className="fab fa-twitter"></i>
          </a>
        </OverlayTrigger>

        <OverlayTrigger
          key={discordPlacement}
          placement={discordPlacement}
          overlay={
            <Tooltip id={`tooltip-${discordPlacement}`}>
              <strong>Discord</strong>
            </Tooltip>
          }
        >
          <a
            className="App-link-discord m-2"
            href={process.env.REACT_APP_DISCORD_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <FontAwesomeIcon icon={faDiscord} /> */}
            <i className="fab fa-discord"></i>
          </a>
        </OverlayTrigger>

        <OverlayTrigger
          key={discordPlacement}
          placement={discordPlacement}
          overlay={
            <Tooltip id={`tooltip-${discordPlacement}`}>
              <strong>Marketplace open</strong>
            </Tooltip>
          }
        >
          <a
            className="magiceden "
            href="https://magiceden.io/marketplace/monkeshitbox"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={magicEden} className="mx-1 mb-3" height="20" />
          </a>
        </OverlayTrigger>
      </h1>
    </header>
  );
}

export default Header;
