import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import title from "../../title-about.png";
import icon from "../../icon-about.png";
// import shitbox from "../../box.png";
import monkesInABox from "../../monkesinabox-soldout.png";

const pageName = "About";

function About(props) {
  return (
    <>
      <a name={pageName.toLowerCase()}></a>
      <p className="App-content">
        <Container>
          <Row>
            <Col>
              <img src={icon} alt={pageName} height="55" className="mr-2" />
              <img src={title} alt={pageName} height="45" className="m-2" />
            </Col>
          </Row>
          <Row xs={1} md={2}>
            <Col md={5}>
              {/* <img src={shitbox} alt={pageName} className="App-content-image" /> */}
              <img
                src={monkesInABox}
                alt={pageName}
                className="App-content-image"
              />
            </Col>
            <Col md={7}>
              <p align="left" className="pt-4">
                MonkeShitBox (MSB) is a collection of 5669 truly random NFTs.
                Most boxes contain a shitty nft.
                <br />
                <code>
                  Some boxes, however, include an NFT redeemable for a{" "}
                  <a
                    href="https://market.solanamonkey.business/"
                    target="_blank"
                  >
                    Solana Monkey Business
                  </a>{" "}
                  NFT (SMB).
                </code>
                <br />
                Some boxes may contain other prizes.
              </p>
              <p align="left" className="mt-3">
                For our genesis MonkeShitBox, we{" "}
                <span className="highlight">guarantee</span> there will be{" "}
                <code>4&nbsp;Solana&nbsp;Monkey&nbsp;Business NFTs</code> and
                that a minimum of&nbsp;
                <code>70% of the mint proceeds</code> will be spent on the boxes
                themselves. Even if you get a shitty NFT, it will{" "}
                <code>still have utility</code>. Including early access on
                future drops, potential for free future ShitBoxes and access to
                the ShitBox-owners only Discord, where we will be{" "}
                <code>constantly giving away un-shitty stuff.</code>
              </p>
            </Col>
          </Row>
        </Container>
      </p>
    </>
  );
}

export default About;
