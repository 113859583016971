import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

function RoadmapCard(props) {
  const { title, checklist } = props;
  return (
    <Card bg="dark" className="roadmap-card m-4">
      {/* <Card.Img variant="top" src={pfp} /> */}

      <Card.Body>
        <Card.Title className="roadmap-card-title">{title}</Card.Title>
        <Card.Text>
          <ul>
            {checklist &&
              Array.isArray(checklist) &&
              checklist?.map((item) => {
                return (
                  <li
                    key={item.name}
                    align="left"
                    className={item.complete ? "checked mt-1" : "unchecked"}
                  >
                    <>
                      <span className="m-2"></span>
                      {item.name}
                    </>
                  </li>
                );
              })}
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default RoadmapCard;
