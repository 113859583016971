import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Offcanvas,
  Nav,
} from "react-bootstrap";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { faDiscord } from "@fortawesome/free-brands-svg-icons";

import title from "../../title-menu.png";
import icon from "../../icon-menu.png";

import titleHome from "../../title-home.png";
import iconHome from "../../icon-home.png";

import titleAbout from "../../title-about.png";
import iconAbout from "../../icon-about.png";

import titleHow from "../../title-howitworks.png";
import iconHow from "../../icon-howitworks.png";

import titleTeam from "../../title-team.png";
import iconTeam from "../../icon-team.png";

import titleRoadmap from "../../title-roadmap.png";
import iconRoadmap from "../../icon-roadmap.png";

import titleFaq from "../../title-faq.png";
import iconFaq from "../../icon-faq.png";
import MonkeIcon from "./MonkeIcon";

const pageName = "Menu";

function Menu(props) {
  return (
    <>
      <Navbar.Offcanvas
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
        placement="end"
        className="canvas-body"
        scroll
        backdrop
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id="offcanvasNavbarLabel">
            <MonkeIcon />
            {/* <img src={icon} alt={pageName} height="46" className="mr-2" /> */}
            <img src={title} alt={pageName} height="42" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link href="/#">
              <img src={iconHome} height="26" className="mr-2" />
              <img src={titleHome} height="26" />
            </Nav.Link>
            <Nav.Link href="/#about">
              <img src={iconAbout} height="26" className="mr-2" />
              <img src={titleAbout} height="26" />
            </Nav.Link>
            <Nav.Link href="/#how it works">
              <img src={iconHow} height="26" className="mr-2" />
              <img src={titleHow} height="26" />
            </Nav.Link>
            <Nav.Link href="/#team">
              <img src={iconTeam} height="26" className="mr-2" />
              <img src={titleTeam} height="26" />
            </Nav.Link>
            <Nav.Link href="/#roadmap">
              <img src={iconRoadmap} height="26" className="mr-2" />
              <img src={titleRoadmap} height="26" />
            </Nav.Link>
            <Nav.Link href="/#faq">
              <img src={iconFaq} height="26" className="mr-2" />
              <img src={titleFaq} height="26" />
            </Nav.Link>
            <h1>
              <a
                className="App-link m-2"
                href={process.env.REACT_APP_TWITTER_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <FontAwesomeIcon icon={faTwitter} /> */}
                <i className="fab fa-twitter"></i>
              </a>

              <a
                className="App-link-discord-dark m-2"
                href={process.env.REACT_APP_DISCORD_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <FontAwesomeIcon icon={faDiscord} /> */}
                <i className="fab fa-discord"></i>
              </a>
            </h1>
            {/* <Nav.Link href="#"></Nav.Link> */}
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </>
  );
}

export default Menu;
