import React from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import solanaMonke from "../../little-solana-monke.png";
import icon from "../../icon-menu.png";

const placement = "left";

function MenuToggle(props) {
  return (
    // <OverlayTrigger
    //   key={placement}
    //   placement={placement}
    //   overlay={
    //     <Tooltip id={`tooltip-${placement}`}>
    //       <strong>Menu</strong>
    //     </Tooltip>
    //   }
    // >
    //   {/* <img src={solanaMonke}></img> */}
    //   <img src={icon} height="44" className="menu-toggle-button mr-2" />
    // </OverlayTrigger>

    <img src={icon} height="44" className="menu-toggle-button mr-2" />
  );
}

export default MenuToggle;
