import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {
  Container,
  Row,
  Col,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Header from "./Components/Common/Header";
import Menu from "./Components/Common/Menu";
import FAQ from "./Components/Common/FAQ";
import About from "./Components/Common/About";

import HowItWorks from "./Components/Common/HowItWorks";
import Team from "./Components/Common/Team";
import MonkeIcon from "./Components/Common/MonkeIcon";

// not deploying to azure with this
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import MenuToggle from "./Components/Common/MenuToggle";

import roadmapJson from "./Components/Common/roadmapConfig.json";
import Map from "./Components/Common/Map";
import MSBRoadmap from "./Components/Common/MSBRoadmap";

import logoPfp from "./icon-logopfp.png";

const placement = "right";

function App() {
  return (
    <>
      <div className="App">
        <Navbar expand={false} sticky="top" className="px-4">
          <Navbar.Brand href="#">
            {/* <span></span> */}
            <OverlayTrigger
              key={placement}
              placement={placement}
              overlay={
                <Tooltip id={`tooltip-${placement}`}>
                  <strong>top</strong>
                </Tooltip>
              }
            >
              <img src={logoPfp} alt={process.env.REACT_APP_NAME} height="50" />
            </OverlayTrigger>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="menu-toggle-button"
          >
            <MenuToggle />
          </Navbar.Toggle>
          <Menu />
        </Navbar>
        <Container fluid>
          <Row>
            <Col>
              <Header />
              <About />
              <MonkeIcon url="#" title="back to top" />
              <HowItWorks />
              <MonkeIcon url="#" title="back to top" />
              <Team />
              <MonkeIcon url="#" title="back to top" />
              <MSBRoadmap json={roadmapJson} />
              <MonkeIcon url="#" title="back to top" />
              <FAQ />
              <MonkeIcon url="#" title="back to top" />
            </Col>
          </Row>
          <Row>
            <Col className="footer">
              {/* <FontAwesomeIcon icon={faCopyright} /> */}
              <i className="far fa-copyright"></i>
              <span className="copyright p-2">10Monkes</span>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default App;
