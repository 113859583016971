import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import title from "../../title-faq.png";
import icon from "../../icon-faq.png";
import Monke from "./Monke";
import Question from "./Question";

const pageName = "FAQ";

function FAQ(props) {
  return (
    <>
      <a name={pageName.toLowerCase()}></a>
      <p className="App-content">
        <Container>
          <Row>
            <Col>
              <img src={icon} alt={pageName} height="55" className="mr-2" />
              <img src={title} alt={pageName} height="45" className="m-2" />

              <Question
                question="What is MonkeShitBox?"
                answer="MonkeShitBox (MSB) is a collection of 5669 truly random Solana NFTs."
                longAnswer="Most contain a shitty NFT designed by our degen team. Some boxes, however, include a Solana Monkey Business NFT (SMB). Some boxes contain other prizes. All boxes have unique rarity and utility. No matter what you find in your boxes, we highly recommend you hold onto at least 1 MonkeShitBox from our genesis 5.67k mint.  Don't say we didn't warn you."
                url="https://market.solanamonkey.business/"
                target="_blank"
                urlText="Solana Monkey Business"
              />

              {/* <Question
                question="Blockchain?"
                answer="Solana"
                url="https://solana.com/"
                target="_blank"
              /> */}

              {/* <Question
                question="How many?"
                answer="There are a total of [5669] Shit Boxes with [4] NFTs redeemable for a SMB."
              /> */}

              <Question
                question="Guaranteed Prizes?"
                answer="We guarantee there will be 4 Solana Monkey Business NFTs and that a minimum of 70% of the mint proceeds will be spent on the boxes and other giveaways."
                // longAnswer="Most of the other boxes are just shit boxes, but they will have rarity and future utility in our ecosystem, including automatic eligibility for future drops, prizes, and giveaways. Expect surprises on Reveal Day."
              />

              <Question
                question="Presale? Whitelist?"
                answer="There is no presale. There is no whitelist. True degen shit show."
              />
              <Question
                question="Mint Date?"
                answer="Some shit date in the not so distant future. Announced via twitter and discord."
              />
              <Question question="Price?" answer="[0.19 SOL]" />
              {/* <Question
                question="Limit to purchase?"
                answer="No Limits on wallets. 1 per transaction."
              /> */}
              {/* <Question
                question="Official website?"
                answer="monkeshitbox.xyz"
                url="https://www.monkeshitbox.xyz"
                target="_blank"
              /> */}
              <Question
                question="Marketplaces?"
                answer="Magic Eden is our preferred marketplace. Boxes will be up very quickly post-mint completion. Since the contents won't be revealed for 24-48 hours, there's a solid chance someone will paperhand something incredible."
              />
              <Question
                question="Team?"
                answer="We have an elite team of 10, all verified SMB MonkeyDAO members."
                longAnswer="We are coming together to try to bring newcomers into the family. Feel free to message @CKS or another team member with questions and we'll get back to you ASAP."
                url="/#team"
                urlText="The Team"
              />

              <Question
                question="I won. How redeem?"
                answer="Did you get an NFT exchangeable for a SMB or something else? "
                longAnswer="If so, we will add you to the Champions Discord channel. There an admin will verify you won and help you exchange your NFT. You will need to send us the NFT, which we will burn. We will never DM you first."
              />

              <Question
                question="I lost. Why should I hold onto my LoserBox?"
                answer="Each box not only has its own rarity, but also secret utility."
                longAnswer="For the people good at reading, we recommend you hold onto your ShitBox, winner or not, as it will get you access to a special Discord channel, whitelist on our 777 ShitMonke PFP collection, and more."
              />

              <Question
                question="The Future?"
                answer="We will never do a 5669 NFT launch again."
                longAnswer="This is our Genesis ShitBox Mint and will be used for future access, utility, and lots of un-shitty stuff. "
              />

              <Question
                question="What happens if the boxes do not sell out?"
                answer="In the unlikely event the shit boxes do not sell out, all minters will be refunded."
              />
              {/* <Question question="" answer="" />
              <Question question="" answer="" /> */}
            </Col>
          </Row>
        </Container>
      </p>
    </>
  );
}

export default FAQ;
